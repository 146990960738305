import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import ReactTable from 'react-table';
import { getOrders, getUserPresDetailsFromPhone, uploadPharmacyPrescription, bookUserPharmacyOrder,getApprovalRequiredOrder } from '../../services/api/get-pharmacy-orders';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';
import ReactTableInputFilter from './ReactTableInputFilter';
import { useCallback } from 'react';
import { convertToOpdConsult } from '../../services/api/get-labs-orders';
import Select from "react-select";
import StarPharmacyOrder from './StarPharmacyOrder';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { CSVLink } from "react-csv";
import reactDom from 'react-dom';

import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import SubComponent from './table-subcomponent/SubComponent';
import RetryOrder from './RetryOrder';
import WalletCoverageModal from './WalletCoverageModal';
import { cancelAndRefundOrder, downloadPHOrdersCsv } from './UploadRxComponents/Services';
import OrderTrackDetailModal from './OrderTrackDetailModal';
import PharmacyActions from './PharmacyActions/index';
import FetchFollowUpRequestsModal from './FetchFollowUpRequestsModal';

const FoldableTable = FoldableTableHOC(ReactTable);

const flexibleDiv ={
  display : 'flex',
  justifyContent : 'space-between',
}

function PharmacyOrdersTable({ startDate, endDate, startDateDelivered, endDateDelivered, phone, showUploadPrescriptionModal, showAddApolloMedicineModal, showRefundOrderModal }) {
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(50);
    const [pages, setPages] = useState(-1);
    const [filtered, setFiltered] = useState([]);
    const [allStatus, setAllStatus] =  useState(['Placed', 'Sample Collected', 'Completed', 'Cancelled']);
    const [sorted, setSorted] = useState([]);
    const [page, setPage] = useState(0);
    const [orders, setOrders] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [userPhone, setUserPhone] = useState(null);
    const [presAddress, setPresAddress] = useState(null);
    const [presPatients, setPresPatients] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedAddress, setSelectedAddress]= useState(null);
    const [userAuthToken, setUserAuthToken] = useState(null);
    const [pharmacyPrescriptionId, setPharmacyPrescriptionId] = useState(null);
    const [ uploadDisabled,setUploadDisabled] = useState(false);
    const [count,setCount]= useState(0);
    const [openStarOrdersModal,setOpenStarOrdersModal] = useState(false);
    const [requiredApprovalOrderCount,setRequiredApprovalOrderCount] = useState(0);
    const [requiredApprovalData,setRequiredApprovalData]= useState(null);
    const [isAdmin, setIsAdmin] = useState(true);
    const [digitisationId , setDigitisationId] = useState('')
    const [showPlaceOrderModal , setShowPlaceOrderModal] = useState(false)
    const [showWalletCoverage, setShowWalletCoverage] = useState(false);
    const [showTrackOrderDetail, setShowTrackOrderDetail] = useState(false);
    const [showFetchFollowUpModal , setShowFetchFollowUpModal] = useState(false);
    const [orderIdForFollowUp, setOrderIdForFollowUp] = useState(null);
    const [walletDigId, setWalletDigId] = useState('');
    const [orderIdToGetTrackOrderDetail, setOrderIdToGetTrackOrderDetail] = useState('');
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [actionModal, setActionModal] = useState({
        orderId: null,
        isOpen: false,
        actionName: null,
        status: null,
        actionType: null,
        collectionDate: null,
        slotId: null,
      });
    const [orderIdToBeCancelled , setOrderIdToBeCancelled] = useState('');

    const [filteredOrders, setFilteredOrders] = useState(orders);
    const [showVipOrdersOnly, setShowVipOrdersOnly] = useState('');

    const getUserDetailsForPrescriptionFromPhone = () => {
      console.log('Clicked');
      if(userPhone === null){
        toast.error('Please Enter Valid Phone');
      }
      if(userPhone !== null){
      getUserPresDetailsFromPhone(userPhone).then(response=>{
        console.log(response);
          if(response.status === 'Success'){
            setPresAddress(response.addressDetails);
            setPresPatients(response.patientDetails);
            setUserAuthToken(response.userAuth);
            toast.success(response.message);
          }
          else if (response.status === 'serverError'){
            toast.error(response.message);
          }
      });
    }
    }

  const getColumns = () => [
    {
      Header: 'Visit Order ID',
      width: 100,
      accessor: 'orderId',
      filterable: true,
      //   width: 75,
      Filter: ReactTableInputFilter,
      Cell: (row) => {
        const { orderId } = row.original;
        return (
          <div style={{ backgroundColor: row.original.isVipUserOrder ? '#FFD700' : 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '0.5rem', borderRadius: '4px' }}>
            <Link
              target="_blank"
              title={orderId}
              to={`/new-pharmacy-order-details?orderId=${orderId}`}
            >
              {orderId}
            </Link>
            &nbsp;&nbsp;{row.original.isVipUserOrder ? 'VIP' : ''}
          </div>
        );
      },
    },
    {
      Header: 'Partner Order Id',
      width: 100,
      accessor: 'partnerBookingId',
      filterable: true,
      Filter: ReactTableInputFilter,
    },
    {
      Header: 'User Name',
      width: 100,
      accessor: 'userName',
      //   width: 100,
      filterable: true,
      Filter: ReactTableInputFilter,
    },
    {
      Header: 'Patient Name',
      width: 100,
      accessor: 'patientName',
      //   width: 100,
      filterable: true,
      Filter: ReactTableInputFilter,
    },

    {
      Header: 'Booking Date',
      width: 100,
      accessor: 'bookedOn',
      //   width: 120,
      // Cell: (row) => (
      //   <span>{moment(row.original.bookingEpoch * 1000).format('Do MMM')}</span>
      // ),
    },
    {
      Header: 'Delivered Date',
      width: 100,
      accessor: 'deliveredDate',
    },
    {
      Header: 'Billed Date',
      width: 100,
      accessor: 'BilledAt',
    },
    {
      Header: 'Cancellation Date',
      width: 100,
      accessor: 'CancelledDate',
    },
    {
      Header: 'Partner',
      width: 100,
      accessor: 'partnerName',
      //   width: 100,
      filterable: true,
      Filter: ReactTableInputFilter,
    },
    {
      Header: 'Sponsor',
      width: 100,
      accessor: 'sponsorName',
      //   width: 100,
      filterable: true,
      Filter: ReactTableInputFilter,
    },
    {
      Header: 'Status',
      width: 100,
      accessor: 'status',
      filterable: true,
      //   width: 100,
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        return row.status === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="Delayed">Delayed</option>
            <option value="Delivered">Delivered</option>
            <option value="Placed">Placed</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Billed">Billed</option>
            <option value="Pending">Pending</option>
            <option value="Failed">Failed</option>
          </select>
        );
      },
    },
    {
      Header: 'State',
      width: 100,
      accessor: 'state',
      //   width: 100,
      filterable: true,
      Filter: ReactTableInputFilter,
    },
    {
      Header: 'Order Type',
      width: 100,
      accessor: 'orderType',
      Cell: (info) => info.value ? info.value.toUpperCase() : "" 
      //   width: 100,
    },
    {
      Header : 'Source Type',
      width: 100,
      accessor:'sourceType',
      Cell: (info) => {
        const sourceType = info.original.sourceType ;
        const orderSponserType = info.original.orderSponserType ;
        if(sourceType == "uploaded-prescription"){
          return "DIGITIZE AND BOOK" ;
        }else if(sourceType == "search"){
          return orderSponserType ;
        }else{
          return sourceType || ""
        }
      }
    },
    {
      Header: 'Tags',
      width: 120,
      accessor: 'tags',
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        return row.status === filter.value;
      },
      Cell: (info) => {
        const followUpCount = info.original && info.original.followUpCount;
        if (!followUpCount) return null; 
      
        return (
          <label style={{borderRadius: '10px', padding: '5px', backgroundColor: '#ECB800', color: 'white', fontSize: '12px', width: 'fit-content',cursor : 'pointer'}} onClick={() => setOrderIdForFollowUp(info.original.orderId)}>
            {`Scheduled (${followUpCount})`}
          </label>
        );
      },
      
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="followup-pending">Pending Follow Up</option>
          </select>
        );
      },
    },
    {
      Header: 'Cancelled By',
      width: 100,
      accessor: 'cancelledBy',
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        return row.status === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="USER">User</option>
            <option value="PARTNER">Partner</option>
            <option value="OPS">Ops</option>
          </select>
        );
      },
    },

    {
      Header: 'Max Delivery Date',
      width: 100,
      accessor: 'maxDeliveryDate',
    },
    {
      Header: 'Min Delivery Date',
      width: 100,
      accessor: 'minDeliveryDate',
    },
    {
      Header : 'Order Delivery Type',
      width: 100,
      accessor:'orderDeliveryType',
    },
    {
      Header : 'Platform',
      width: 100,
      accessor:'platform',
    },
    {
      Header: 'Actions',
      numeric: false,
      disablePadding: false,
      filterable: false,
      accessor: 'actions',
      width: 195,
      style: {
        position: 'sticky',
        right: 0,
        top: 0,
        background: 'white',
        textAlign: 'center',
        zIndex: 2,
        alignContent: 'center',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
      },
      headerStyle: {
        overflow: 'visible',
        position: 'sticky',
        right: 0,
        background: 'white',
        textAlign: 'center',
        zIndex: 2,
      },
      Cell: (row) => (
        <div style={{
          overflow: 'visible',
          position: 'sticky',
          right: 0,
          background: 'white',
          textAlign: 'center',
          zIndex: 2,}
        }>
          <PharmacyActions orderId={row.original.orderId}/>
        </div>
      ),
    }
    
  ];


  const prepareApiFilters = useCallback(() => {
    const appliedFilters = {};
    // let appliedOrderBy = {};
    // Convert this list of filterables into object properties
    filtered.forEach(
      (filter) => (appliedFilters[filter.id] = filter.value)
    );

    appliedFilters.rowsPerPage = pageSize;
    appliedFilters.page = page + 1;
    appliedFilters.phone = phone ? phone : '';
    appliedFilters.deliveredFrom = startDateDelivered && endDateDelivered ? moment(startDateDelivered).format("YYYY-MM-DD 00:00:00") : '';
    appliedFilters.deliveredTo = endDateDelivered && startDateDelivered ? moment(endDateDelivered).format("YYYY-MM-DD 23:59:59") : '';
    appliedFilters.bookingFrom = startDate && endDate ? moment(startDate).format("YYYY-MM-DD 00:00:00") : '';
    appliedFilters.bookingTo = endDate && startDate ? moment(endDate).format("YYYY-MM-DD 23:59:59") : '';
    appliedFilters.showVipOrdersOnly = showVipOrdersOnly ? 1 : '';
    return appliedFilters;

  }, [filtered, pageSize, page, phone, startDateDelivered, endDateDelivered, startDate, endDate, showVipOrdersOnly]);


    
 const updateStateFilters = (id, value, id2, value2) => {
    console.log('Update all filters in state', { id, value, id2, value2 });
    // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
    const filter = filtered.find((f) => f.id === id);
    const filter2 = filtered.find((f) => f.id === id2);
    if (filter) {
      filter.value = value;
    } else {
      setFiltered([...filtered, {id,value}]);
    }
    if (filter2) {
      filter2.value = value2;
      setFiltered([...filtered, {id,value}]);
    } else {
      setFiltered([...filtered, { id: id2, value: value2 }]);
    }
  };

  const onFilteredChange = (e) => {
    setFiltered(e)
  };
    

    const  fetchOrders = useCallback(() => {
        setLoading(true);
        const appliedFilters = prepareApiFilters();
        getOrders(appliedFilters, sorted)
            .then((response) => {
            console.log('Api response', response.body);
            const pages =  Math.ceil (response.body.count / pageSize)
            // const pages =
            //     response.body.count % appliedFilters.rowsPerPage === 0
            //     ? parseInt(response.body.count / appliedFilters.rowsPerPage)
            //     : parseInt(response.body.count / appliedFilters.rowsPerPage) + 1;

                // setOrders(response.body.orders);
                setFilteredOrders(response.body.orders);
                setLoading(false);
                setPages(pages);
                setCount(response.body.count)
                setIsAdmin(response.body.isAdmin === 1 ? true : false)
            })
            .catch((err) => {
            console.error(err, 'Error in get labs orders API');
            toast.error(`Error in fetching orders`);
            });
        }, [pageSize, prepareApiFilters, sorted]);

    useEffect(()=>{
        fetchOrders();
    }, [fetchOrders]);

    const OpenModal = ()=>{ 
      setOpenStarOrdersModal(true);
    }
    const getOrdersForApprovals =useCallback(() => {
      getApprovalRequiredOrder()
      .then((res)=>{
      if(res.count>0){
        setRequiredApprovalOrderCount(res.count);
        setRequiredApprovalData(res.orders );
         
      }
      }).catch((err)=>{
        console.log(err );
      })
    },[]);
    
    //getOrdersForApprovals();
    useEffect(()=>{
      getOrdersForApprovals();
    },[getOrdersForApprovals])

    //Handle Phone Search
    // useEffect(()=>{
    //     if(phone !== null){
    //         const tempList = orders.filter((order) => {
    //             return (
    //                 order.patientPhone.toString().toLowerCase().indexOf(phone.toLowerCase()) !==
    //                 -1
    //             );
    //           });
    //           setFilteredOrders(tempList);
    //     }
    // }, [orders, phone]);

    useEffect(()=>{
      
    }, [userAuthToken, userPhone]);

    const placeOrder = () => {
    }

    const handleSelectedAddressChange = (address) => {
      setSelectedAddress(address);
    }

    const handleSelectedPatientChange = (patient) => {
      setSelectedPatient(patient);
    }

    const addPrescriptionFile = () => {
      document.querySelector('#add-prescription-input').click();
    };

    const onPrescriptionFileAdded = () => {
      const file = document.querySelector('#add-prescription-input').files[0];
      console.log('datat phone',userPhone)
      const toastId = toast('Uploading', {
        position: 'top-center',
        closeButton: false,
        closeOnClick: false,
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        progress: 0,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          toast.update(toastId, {
            progress: loaded / total,
          });
        }
      };
      if(file){
        setUploadDisabled(true);
    return   uploadPharmacyPrescription(file, userPhone, onUploadProgress, userAuthToken).then(response=>{
        console.log(response, 'Pharmacy Prescription Id');
        if(response.message === 'success'){
          toast.success('Successfully Uploaded');
        setUploadDisabled(false);
        }
        setPharmacyPrescriptionId(response);
      })
    }
    }
    const handleDigitisationInput = (e) => {
      const value = e.target.value;
  
      // Remove any non-numeric characters
      const formattedValue = value.replace(/[^0-9]/g, '');
  
      // Update the state with the formatted value
      setDigitisationId(formattedValue);
    };
   const fetchStrategy = (state) => this.fetchDataWithDebounce(state);

   const handleShowWalletCoverageDetails = (e) => {
    const value = e.target.value;

    // Remove any non-numeric characters
    const formattedValue = value.replace(/[^0-9]/g, '');

    // Update the state with the formatted value
    setWalletDigId(formattedValue);
  };

  const handleShowTrackOrderDetails = (e) => {
    const value = e.target.value;

    // Remove any non-numeric characters
    const formattedValue = value.replace(/[^0-9]/g, '');

    // Update the state with the formatted value
    setOrderIdToGetTrackOrderDetail(formattedValue);
  };

    const bookUserPharmacyOrderButton = () => {
      return bookUserPharmacyOrder(selectedAddress.value, selectedPatient.value, pharmacyPrescriptionId, userPhone).then(response=>{
        if(response.message==="success"){
          toast.success(response.showMessage);
          clearCurrentUser();
        }
        else{
          toast.error('Error in Uploading Digitisation');
        }
      });  
    }
    const downloadCsv = async()=> {
      const appliedFilters = prepareApiFilters();
      setIsDownloadingCsv(true);
      await downloadPHOrdersCsv(appliedFilters);
      setIsDownloadingCsv(false);
    }
    const handleOrderIdInput = (e) => {
      const value = e.target.value;
      const formattedValue = value.replace(/[^0-9]/g, '');
      setOrderIdToBeCancelled(formattedValue);
    };

    const CancelAndRefundOrder = async() => {
      const response = await cancelAndRefundOrder(orderIdToBeCancelled);
      alert(response.message);
    }

    const clearCurrentUser = () => {
      setPresAddress(null);
      setPresPatients(null);
      setSelectedPatient(null);
      setSelectedAddress(null);
      setUserPhone(null);
      setSelectedFile(null);
      setUserAuthToken(null);
      setPharmacyPrescriptionId(null);
      setShowVipOrdersOnly('');
    }
    
  return (
    <React.Fragment>
      <div  style={ {...flexibleDiv , marginTop : '16px'}}>
        <div>
        <h4>
          Total Count - {count}
        </h4>
        </div>
        <div style={flexibleDiv}>
          <input type="text" 
               placeholder='Enter Order id'  
               onKeyDown={(e) => e.key === 'Enter' ? CancelAndRefundOrder(): null} 
               onChange={handleOrderIdInput}
               value={orderIdToBeCancelled} />
          <div class="input-group-append">
            <button disabled= {orderIdToBeCancelled && isAdmin ? false : true} class="btn btn-primary" type="button" onClick={() => CancelAndRefundOrder()}>Cancel Order</button>
          </div>
        </div>
        <div style={flexibleDiv}>
          <input type="text" 
               placeholder='Enter digitisation id'  
               onKeyDown={(e) => e.key === 'Enter' ? setShowPlaceOrderModal(true) : null} 
               onChange={handleDigitisationInput}
               value={digitisationId} />
          <div class="input-group-append">
             <button disabled= {digitisationId && isAdmin ? false : true} class="btn btn-primary" type="button" onClick={() => setShowPlaceOrderModal(true)}>Get Carts</button>
          </div>
        </div>
        <div style={flexibleDiv}>
          <input type="text" 
               placeholder='Enter digitisation id'  
               onKeyDown={(e) => e.key === 'Enter' ? setShowWalletCoverage(true) : null} 
               onChange={handleShowWalletCoverageDetails}
               value={walletDigId} />
          <div class="input-group-append">
             <button disabled= {walletDigId && isAdmin ? false : true} class="btn btn-primary" type="button" onClick={() => setShowWalletCoverage(true)}>Wallet Coverage</button>
          </div>
        </div>
        <div style={flexibleDiv}>
          <input type="text"
            placeholder='Enter order id'
            onKeyDown={(e) => e.key === 'Enter' ? setShowTrackOrderDetail(true) : null}
            onChange={handleShowTrackOrderDetails}
            value={orderIdToGetTrackOrderDetail} />
          <div class="input-group-append">
            <button disabled={orderIdToGetTrackOrderDetail ? false : true} class="btn btn-primary" type="button" onClick={() => setShowTrackOrderDetail(true)}>Track Order</button>
          </div>
        </div>
      </div>
        <section className='p-3'>
        <div className=''>
          <button className='btn btn-primary float-right mx-2'
            onClick={OpenModal}
            disabled={requiredApprovalOrderCount === 0}
          >
            Approval Pending - {requiredApprovalOrderCount}

          </button>
          <button className='btn btn-primary float-right mx-2'
            onClick={() => setShowVipOrdersOnly(1)}
          >
            Only VIP Users
          </button>
          <button className='btn btn-primary float-right mx-2'
            disabled={!isAdmin}
            onClick={showAddApolloMedicineModal}
          >
            Add Apollo Medicine
          </button>
          <button className='btn btn-primary float-right mx-2'
            disabled={!isAdmin}
            onClick={showRefundOrderModal}
          >
           Refund Amount
          </button>
          <button className='btn btn-primary float-right mx-2'
            disabled={!isAdmin}
            onClick={showUploadPrescriptionModal}
          >
            Upload Prescription
          </button>
        </div>
        <div className='d-flex'>
        {presAddress &&
         <div class="input-group mb-3 p-1">
          <div class="card text-white bg-primary mb-3">
            <div class="card-header">Selected User</div>
            <div class="card-body">
              <h5 class="card-title">{userPhone}</h5>
              <button class="btn btn-warning" type="button" onClick={clearCurrentUser}>Clear Current User</button>
            </div>
          </div>
       </div>}
        </div>
        <div className='d-flex'>
      {presAddress !== null && presPatients !== null && userAuthToken !== null ?
       <div class="input-group mb-3 p-1">
         <div className='col-3'><Select options={presAddress} placeholder='Addresses' onChange={handleSelectedAddressChange} value={selectedAddress}/></div>
         <div className='col-3'><Select options={presPatients} placeholder='Patients' onChange={handleSelectedPatientChange} value={selectedPatient}/></div>
          <div class="custom-file">
            {/* <input type="file" class="custom-file-input" id="inputGroupFile02" onChange={e=>{
              e.preventDefault();
              setSelectedFile(e.target.files[0]);
            }}/>
            <label class="custom-file-label" for="inputGroupFile02">Choose Prescription</label> */}
          </div>
            {pharmacyPrescriptionId === null ?
            <div class="">
          <input
                    type="file"
                    id="add-prescription-input"
                    accept=".png,.jpg,.jpeg,.pdf"
                    style={{ display: 'none' }}
                    onChange={onPrescriptionFileAdded}
                  />
           <Button
                    outline
                    color="primary"
                    onClick={addPrescriptionFile}
                    disabled={uploadDisabled}
                  >
                    Add Prescription
                  </Button>

          </div> : selectedAddress !== null && selectedPatient !== null ? <><Button class='btn btn-success' onClick={bookUserPharmacyOrderButton}>Place Order</Button></> : <>Select Address and Patient</>}
        </div>: <></>}
        </div>
        </section>
        <div className='pull-right d-flex'>
        <button  className='btn btn-primary float-right mx-2' onClick={downloadCsv}>{isDownloadingCsv ? 'Downloadin ...' : 'Download CSV'}</button>
        </div>
        <FoldableTable
          keyField="orderId"
          data={filteredOrders}
          columns={getColumns()}
          defaultPageSize={50}
          pages={pages}
          pageSize={pageSize}
          page={page}
          loading={loading}
          filtered={filtered}
          sorted={sorted}
          manual
          SubComponent={(row) => (
            <SubComponent
              orderId={row.original.orderId}
              userId={row.original.userId}
            />
          )}
          //onFetchData={fetchStrategy}
          onFilteredChange={onFilteredChange}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          onPageChange={(page) => setPage(page)}
          onSortedChange={(sorted) => setSorted(sorted)}
          className="-striped -highlight revamp-table"
          showPaginationTop
          showPaginationBottom
        />
        <StarPharmacyOrder
          isOpen={openStarOrdersModal}
          tableSource={requiredApprovalData}
     getOrdersForApprovals = {getOrdersForApprovals}
     onClose = {()=>setOpenStarOrdersModal(false)}

          
        ></StarPharmacyOrder>
          {showPlaceOrderModal && <RetryOrder digitisationId = {digitisationId} onClose ={()=> setShowPlaceOrderModal(false)} />}
      {showWalletCoverage && <WalletCoverageModal onClose={() => setShowWalletCoverage(false)} digitisationId={walletDigId} />}
      {showTrackOrderDetail && <OrderTrackDetailModal onClose={() => setShowTrackOrderDetail(false)} orderId={orderIdToGetTrackOrderDetail} />}
      {orderIdForFollowUp && <FetchFollowUpRequestsModal onClose = {() => setOrderIdForFollowUp(null)} orderId = { orderIdForFollowUp} /> }  
      </React.Fragment>
      
      
  )
}

export default PharmacyOrdersTable