import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import csvParse from 'csv-parse/lib/sync';
import ResultModal from '../common/modal/ResultModal';
import {
  OuterComponent,
  HeadingContainer,
  PaddedContainer,
  BodyContainer,
} from './StyledComponent';
import { Button } from 'reactstrap';
import OnlineConsultConfirmModal from './OnlineConsultConfirmModal';
import LoadingComponentAbsolute from '../common/LoadingComponentAbsolute';
import { onlineConsultationBooking } from '../../services/api/online-consultation-booking';
import Select from 'react-select';
import { fetchAllSponsors } from '../../services/api/sponsors';
import ConsultOnlineModal from '../common/modal/consultOnlineModal';
import DependentModal from '../common/modal/dependentModal';
// import OpdHolderTable from './OpdHolderTable';
// import UpdateOpdMembersTable from './UpdateOpdMembersTable';

const newConsultationUserCols = ['EMP_NAME', 'DOC_ID', 'MOBILE_NO', 'DOB', 'E_MAIL', 'PLATFORM'];

const sampleFileUrl =
  'https://visit-public.s3.ap-south-1.amazonaws.com/operations-dashboard/add-opd-members-sample.csv';

class OnlineConsultationBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      showOnlineConfirmModal: false,
      summary: [],
      premium: '',
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      data: [],
      tableData: [],
      submitId: '',
      sponsorId: null,
      showUpdateTable: false,
      selectedOption: null,
      showOpdTable: true,
      invalidRecord: [],
      columnsNames: [],
      sponsors: [],
      records: [],
      search: '',
      pageSize: 10,
      pages: 0,
      filtered: [],
      bookConsultModal:false,
      addDependentModal:false
    };
  }
  componentDidMount = () => {
    this.getSponsors();
  };
  getSponsors = () => {
    return fetchAllSponsors()
      .then((response) => {
        console.log(response, 'Response from server');
        const sponsors = response.data.map((sponsor) => {
          return {
            value: sponsor.sponsorId,
            label: sponsor.sponsorName,
          };
        });
        this.setState({
          sponsors,
        });
      })
      .catch(() => {
        this.setState({
          sponsors: [],
        });
      });
  };
  // Check the data parse
  // Onboard New Members
  checkOnboardData = (data) => {
    // console.log(data.length, '[checkOnboardData]');
    const columnsNames = data.shift();
    console.log(columnsNames, '[columnNames]');
    const records = data;
    if (this.state.sponsorId) {
      if (columnsNames.length === newConsultationUserCols.length) {
        // console.log(records, 'records');
        let columnMatch = true,
          message = '';
        for (let i = 0; i < columnsNames.length; i++) {
          if (columnsNames[i] !== newConsultationUserCols[i]) {
            columnMatch = false;
            message = `Column name doesn't match. Expected column name ${newConsultationUserCols[i]} get ${columnsNames[i]}`;
          }
        }
        if (columnMatch) {
          onlineConsultationBooking(columnsNames, records, this.state.sponsorId)
            .then((resp) => {
              console.log({ resp }, 'onlineConsultationBooking');
              this.setState({
                showOnlineConfirmModal: true,
                modalHeader: 'Success',
                invalidRecord: resp.invalidRecord,
              });
            })
            .catch((err) => {
              let errorMessage = err.message;
              if (err.response !== undefined) {
                errorMessage = err.response.data.errorDebug;
              }
              // console.log(errorMessage);
              this.setState({
                showModal: true,
                modalHeader: 'Parsing Failed',
                modalBody: `Error : "${errorMessage}". Check the csv file and try again.`,
                bgColor: 'bg-danger',
              });
            });
        } else {
          this.setState({
            showModal: true,
            modalHeader: 'Parsing Failed',
            modalBody: `Error : ${message}`,
            bgColor: 'bg-danger',
          });
        }
      } else {
        this.setState({
          showModal: true,
          modalHeader: 'Parsing Failed',
          modalBody:
            'Error : columns length of csv file is not equal to the required format. Check the csv format and try again',
          bgColor: 'bg-danger',
        });
      }
    } else {
      this.setState({
        showModal: true,
        modalHeader: 'Warning',
        modalBody: 'Please select sponsor first.',
        bgColor: 'bg-warning',
      });
    }
  };
  handleOnboard = () => {
    document.querySelector('#csvOnboardingAttachment').click();
  };
  handleOnboardAttachment = () => {
    const file = document.querySelector('#csvOnboardingAttachment').files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      // console.log({ res });

      try {
        const data = csvParse(res, {
          // columns: true,
          skip_empty_lines: true,
          quote: '"',
          ltrim: true,
          rtrim: true,
          delimiter: ',',
        });
        // console.log({ data });
        this.setState({ data: data });
        console.log(this.state.data, 'dataaas');
        this.checkOnboardData(data);
      } catch (err) {
        // console.log(err.message, 'err in parse');
        this.setState({
          showModal: true,
          modalHeader: 'Failed',
          modalBody: err.message,
          bgColor: 'bg-danger',
        });
      }
    };
    reader.readAsText(file);
    document.querySelector('#csvOnboardingAttachment').value = null;
  };
  // Update Member Details
  handleUpdate = () => {
    document.querySelector('#csvUpdateAttachment').click();
  };
  handleUpdateAttachment = () => {
    const file = document.querySelector('#csvUpdateAttachment').files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      // console.log({ res });

      try {
        const data = csvParse(res, {
          // columns: true,
          skip_empty_lines: true,
          quote: '"',
          ltrim: true,
          rtrim: true,
          delimiter: ',',
        });
        const tableData = csvParse(res, {
          columns: true,
          skip_empty_lines: true,
          quote: '"',
          ltrim: true,
          rtrim: true,
          delimiter: ',',
        });
        // console.log({ data, tableData });
        this.setState({ data: data });
        // console.log(this.state.data, 'dataaas');
        this.checkUpdateData(data, tableData);
      } catch (err) {
        // console.log(err.message, 'err in parse');
        this.setState({
          showModal: true,
          modalHeader: 'Failed',
          modalBody: err.message,
          bgColor: 'bg-danger',
        });
      }
    };
    reader.readAsText(file);
    document.querySelector('#csvUpdateAttachment').value = null;
  };
  handleChange = (event) => {
    console.log({ event });
    console.log('data', this.sta);
    this.setState({ sponsorId: event.value, selectedOption: event });
  };

  openBookConsultModal = () => {
    console.log('check  ')
    this.setState({
      bookConsultModal:true
    })
  }

  addDependentModal = () =>{
    this.setState({
      addDependentModal:true
    })
  }
  render() {
    return (
      <OuterComponent>
        <NavBar />
        <HeadingContainer>
          <h3 style={{ marginLeft: 20 }}>
            Online Consultation Booking - (for partners like Axis Bank)
          </h3>
          <p style={{ marginLeft: 20 }}>
            Valid columns names - 'EMP_NAME', 'DOC_ID', 'MOBILE_NO', 'DOB', 'E_MAIL', 'PLATFORM'.
            <br />
            Please check only these columns present with same order and columns
            name in csv before uploading to book consultation.
          </p>
        </HeadingContainer>
        <div
          style={{
            marginLeft: 20,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              width: '20%',
              height: '40px',
              fontSize: '12px',
            }}
          >
            <Select
              onChange={this.handleChange}
              options={this.state.sponsors}
              placeholder="Select sponsors"
              value={this.state.selectedOption}
            />
          </div>
        </div>
        <PaddedContainer>
          <div className="col-3">
            <input
              type="file"
              id="csvOnboardingAttachment"
              accept=".csv"
              style={{ display: 'none' }}
              onChange={this.handleOnboardAttachment}
            />
            <Button onClick={this.handleOnboard}>Book Consultation</Button>
          </div>
          {/* <div className="col-3">
            <button
              className="btn btn-primary"
              onClick={() => window.open(sampleFileUrl, '_blank')}
            >
              Download Sample file
            </button>
          </div> */}
          <div className='col-3'>
            <Button onClick={()=>this.openBookConsultModal()}>
              Book Online Consult
            </Button>
          </div>
          <div className='col-3'>
            <Button onClick={()=>this.addDependentModal()}>
              Add Dependent
            </Button>
          </div>
        </PaddedContainer>
        {this.state.showOnlineConfirmModal && (
          <OnlineConsultConfirmModal
            modalHeader={this.state.modalHeader}
            closeModal={() =>
              this.setState({ showOnlineConfirmModal: false, loading: false })
            }
            invalidRecord={this.state.invalidRecord}
          />
        )}
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.bookConsultModal && (
          <ConsultOnlineModal 
            openModal={this.openBookConsultModal}
            modalIsOpen = {this.state.bookConsultModal}
            closeModal={()=>this.setState({bookConsultModal:false})}
          />
        )}
        {this.state.addDependentModal && (
          <DependentModal 
            openModal={this.addDependentModal}
            modalIsOpen = {this.state.addDependentModal}
            closeModal={()=>this.setState({addDependentModal:false})}
          />
        )}
        {this.state.loading ? <LoadingComponentAbsolute /> : null}
      </OuterComponent>
    );
  }
}

export default OnlineConsultationBooking;
